export default {
  data() {
    return {};
  },
  mounted() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/wechat-open-api/official-account/getJSSDKConfig?' + 'jsApiList=scanQRCode&jsApiList=getNetworkType&url=' + encodeURI(window.location.href));
    xhr.send();
    xhr.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      wx.config(JSON.parse(xhr.response).data);
      // eslint-disable-next-line no-undef
      wx.ready(function () {
        // eslint-disable-next-line no-undef
        wx.scanQRCode();
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateBack();
      });
    });
  },
  computed: {},
  methods: {}
};